import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../../components/layout/Layout.component";
import Author from "../../../components/author/Author.component";
import * as style from "../Project.style.module.scss";

const CheapSites = () => {
  return (
    <Layout>
      <StaticImage
        src="./images/markus-spiske-1LLh8k2_YFk-unsplash.jpg"
        as="header"
        className={style.hero}
      />
      <main className={style.post}>
        <article className={style.article}>
          <div className={style.date}>Project</div>
          <h1>Cheap Sites</h1>
          <h2>Introduction</h2>
          <p>
            An attempt to interest small businesses with a website. We wanted to
            create competition for facebook pages. We wanted everyone to be able
            to afford a website. The price includes a domain, space on the
            server, and a template. His task was only to provide pictures and
            texts for replacement. Of course, each of the available templates
            was designed by me.
          </p>
          <h2>Result</h2>
          <StaticImage src="./images/screenshot_cheap_sites.png" as="div" />
          <h2>Templates</h2>
          <h3>App</h3>
          <StaticImage src="./images/themes/App.png" as="div" />
          <h3>Candle Shop</h3>
          <StaticImage src="./images/themes/Candle Shop.png" as="div" />
          <h3>Doctor</h3>
          <StaticImage src="./images/themes/Doctor.png" as="div" />
          <h3>Home</h3>
          <StaticImage src="./images/themes/Home.png" as="div" />
          <h3>Mounatins</h3>
          <StaticImage src="./images/themes/Mounatins.png" as="div" />
          <h3>Route</h3>
          <StaticImage src="./images/themes/Route.png" as="div" />
          <h3>Technology</h3>
          <StaticImage src="./images/themes/Technology.png" as="div" />
          <h3>Urban</h3>
          <StaticImage src="./images/themes/Urban.png" as="div" />
          <h3>Vietnam</h3>
          <StaticImage src="./images/themes/Vietnam.png" as="div" />
        </article>
        <aside>
          <Author />
        </aside>
      </main>
    </Layout>
  );
};
export default CheapSites;

export function Head() {
  return <title>Cheap Sites</title>;
}
