import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import * as style from "./Author.style.module.scss";

const Author = () => {
  return (
    <div className={style.author_data}>
      <StaticImage
        alt="Bartlomiej Nowak"
        src="../../images/Bartlomiej_Nowak.svg"
        as="div"
        className={style.img}
      />
      <p className={style.author}>Bartłomiej Nowak</p>
      <p>
        I am a professional designer and frontend developer. In my free time, I
        develop my interests and learn new things.
      </p>
    </div>
  );
};

export default Author;
